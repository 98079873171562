<template>
    <div>
        <p class="mb-1">
            {{ $t('legalInfo.reason') }}
        </p>
        <div v-if="clientType === 'FO'">
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.realEstate"
                :label="$t('legalInfo.reasons.realEstate')"
                v-model="realEstate"
            />
            <div v-if="realEstate" class="mt-4 mb-5 bg-cyrrus-gray-light p-3">
                <uploader-row
                    class="bg-white p-3 card shadow-sm"
                    :title="$t('legalInfo.reasonsRealEstateUploadsText')"
                    :no-documents-text="$t('legalInfo.reasonsNoUploads')"
                    :url="uploaderUrl"
                    :uploads="uploads"
                    :removed-uploads="removedUploads"
                    :filtered-stored-uploads="filteredInfoUploads"
                    meta-field="reasons.realEstate"
                    @uploaded="onUploadUploaded"
                    @remove="onUploadRemove"
                    @restore="onUploadRestore"
                />
            </div>
        </div>
        <div v-if="clientType !== 'PO'">
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.car"
                :label="$t('legalInfo.reasons.car')"
                v-model="car"
            />
            <div v-if="car" class="mt-4 mb-5 bg-cyrrus-gray-light p-3">
                <uploader-row
                    class="bg-white p-3 card shadow-sm"
                    :title="$t('legalInfo.reasonsCarUploadsText')"
                    :no-documents-text="$t('legalInfo.reasonsNoUploads')"
                    :url="uploaderUrl"
                    :uploads="uploads"
                    :removed-uploads="removedUploads"
                    :filtered-stored-uploads="filteredInfoUploads"
                    meta-field="reasons.car"
                    @uploaded="onUploadUploaded"
                    @remove="onUploadRemove"
                    @restore="onUploadRestore"
                />
            </div>
        </div>
        <div v-if="clientType === 'FO'">
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.holiday"
                :label="$t('legalInfo.reasons.holiday')"
                v-model="holiday"
            />
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.personal"
                :label="$t('legalInfo.reasons.personal')"
                v-model="personal"
            />
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.investment"
                :label="$t('legalInfo.reasons.investment')"
                v-model="investment"
            />
        </div>

        <div v-if="clientType !== 'FO'">
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.invoice"
                :label="$t('legalInfo.reasons.invoice')"
                v-model="invoice"
            />
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.businessActivities"
                :label="$t('legalInfo.reasons.businessActivities')"
                v-model="businessActivities"
            />
            <form-input-checkbox
                class="mb-1"
                id="legalInfo.reasons.operatingCosts"
                :label="$t('legalInfo.reasons.operatingCosts')"
                v-model="operatingCosts"
            />
        </div>
        <form-input-checkbox
            class="mb-1"
            :label="$t('legalInfo.reasons.other')"
            v-model="other"
        />
    </div>
</template>

<script>
import UploaderRow from './UploaderRow.vue'

export const sortedReasons = [
    'realEstate',
    'car',
    'holiday',
    'personal',
    'investment',
    'invoice',
    'businessActivities',
    'operatingCosts',
    'other'
]

export default {
    name: 'LegalInfoReason',
    components: {
        UploaderRow
    },
    props: {
        uploaderUrl: {
            type: String,
            required: true
        },
        uploads: {
            type: Array,
            required: false,
            default: () => []
        },
        removedUploads: {
            type: Array,
            required: false,
            default: () => []
        },
        filteredInfoUploads: {
            type: Array,
            required: false,
            default: () => []
        },
        clientType: {
            type: String,
            required: false,
            default: 'FO'
        },
        value: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            realEstate: this.value.includes('realEstate'),
            car: this.value.includes('car'),
            holiday: this.value.includes('holiday'),
            personal: this.value.includes('personal'),
            investment: this.value.includes('investment'),
            invoice: this.value.includes('invoice'),
            businessActivities: this.value.includes('businessActivities'),
            operatingCosts: this.value.includes('operatingCosts'),
            other: this.value.includes('other')
        }
    },
    computed: {
        reasonsStringify () {
            return JSON.stringify({
                realEstate: this.realEstate,
                car: this.car,
                holiday: this.holiday,
                personal: this.personal,
                investment: this.investment,
                invoice: this.invoice,
                businessActivities: this.businessActivities,
                operatingCosts: this.operatingCosts,
                other: this.other
            })
        }
    },
    watch: {
        value () {
            this.$nextTick(() => {
                this.realEstate = this.value.includes('realEstate')
                this.car = this.value.includes('car')
                this.holiday = this.value.includes('holiday')
                this.personal = this.value.includes('personal')
                this.investment = this.value.includes('investment')
                this.invoice = this.value.includes('invoice')
                this.businessActivities = this.value.includes('businessActivities')
                this.operatingCosts = this.value.includes('operatingCosts')
                this.other = this.value.includes('other')
            })
        },
        reasonsStringify () {
            this.$nextTick(() => {
                this.$emit('input', Object.entries(JSON.parse(this.reasonsStringify))
                    .map(([key, value]) => value ? key : null).filter(Boolean)
                )
            })
        }
    },
    methods: {
        onUploadUploaded (serverData, metaField) {
            this.$emit('uploaded', serverData, metaField)
        },
        onUploadRestore (upload, metaField) {
            this.$emit('restore', upload, metaField)
        },
        onUploadRemove (upload, metaField) {
            this.$emit('remove', upload, metaField)
        }
    }
}
</script>
